<template>
    <div class="signup-box">
        <div class="center">
            <div class="panel">
                <p class="title">
                    <span>用户注册</span>
                </p>
                <el-form :model="signupForm" :rules="rules" ref="signupForm" label-width="100px">
                  <el-form-item label="登录名" prop="username">
                    <el-input v-model="signupForm.username" placeholder="请输入您的手机号"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="varCode" class="mar">
                    <el-input v-model="signupForm.varCode" placeholder="请输入短信验证码">
                      <el-button :class="{'sent': signupForm.isSend}" slot="append" @click="getCode()">
                        <span v-if="!signupForm.isSend">获取验证码</span>
                        <span v-else>{{signupForm.s}}S后重发</span>
                      </el-button>
                    </el-input>
                  </el-form-item>
<!--                    <el-form-item label="登录名" prop="username">
                        <el-input v-model="signupForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="昵称" prop="staffName">
                        <el-input v-model="signupForm.staffName"></el-input>
                    </el-form-item>-->
                    <el-form-item label="设置密码" prop="passWord">
                        <el-input v-model="signupForm.passWord" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirmPwd">
                        <el-input v-model="signupForm.confirmPwd" type="password"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="真实姓名" prop="userCode">
                        <el-input v-model="signupForm.certificatecode"></el-input>
                    </el-form-item>
                    <el-form-item label="证件类型">
                        <el-select v-model="signupForm.certificatetype" placeholder="请选择证件类型">
                            <el-option label="身份证" value="1"></el-option>
                            <el-option label="户口本" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="certificatecode">
                        <el-input v-model="signupForm.certificatecode"></el-input>
                    </el-form-item>

                    <el-form-item label="手机" prop="telephone">
                        <el-input v-model="signupForm.telephone"></el-input>
                    </el-form-item>-->

                    <el-form-item class="signup-tips mar">
                        <el-checkbox v-model="signupForm.checked">我已阅读并同意</el-checkbox>
                        <span class="agreement" @click="dialogVisible = true">《注册协议》</span>
                    </el-form-item>
                    <el-form-item class="signup-btn mar">
                        <el-button @click="signup()" :disabled="!signupForm.checked">立即注册</el-button>
                    </el-form-item>
                    <el-form-item>
                        <p class="is-account">
                            已注册？
                            <router-link to="/login">去登录</router-link>
                        </p>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog
                title="注册协议"
                :visible.sync="dialogVisible"
                width="1200px"
                top="10vh">
            <div class="agree-text">
                <p>欢迎您在“自然资源统一确权登记”注册，请确认您已充分阅读、理解并接受本协议的全部内容，同意遵循本协议的所有约定。</p>
                <h4>1. 服务内容</h4>
                <p>注册用户在“自然资源统一确权登记”能够进行自然资源所有权登记成果的查询、参与平台互动，并可使用收藏、关注、分享等个性化服务。</p>
                <h4>2. 注册信息提供及账号管理</h4>
                <p>（1）用户在“自然资源统一确权登记”注册时，需提供准确的个人资料，如个人资料有任何变动，请及时更新。</p>
                <p>（2）用户注册成功后，“自然资源统一确权登记”将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
                <h4>3.用户承诺和授权</h4>
                <p>用户在“自然资源统一确权登记”注册后，必须承诺并确认：</p>
                <p>（1）遵守中国有关的法律和法规；</p>
                <p>（2）不得为任何非法目的而使用网络服务系统；</p>
                <p>（3）遵守所有与网络服务有关的网络协议、规定和程序；</p>
                <p>（4）不得利用“自然资源统一确权登记”进行任何可能对互联网的正常运转造成不利影响的行为；</p>
                <p>（5）不得利用“自然资源统一确权登记”传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</p>
                <p>（6）不得利用“自然资源统一确权登记”进行任何不利于自然资源确权登记成果社会化公众展示的行为；</p>
                <p>（7）如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告“自然资源统一确权登记”。</p>
                <p>（8）“自然资源统一确权登记”提供的网络服务内容可能包括：文字、软件、声音、图片、录象、图表等。所有这些内容受版权、商标和其他财产所有权法律的保护。</p>
                <p>（9）用户只有在获得“自然资源统一确权登记”或其他相关权利人的授权之后才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
                <h4>4. 隐私保护</h4>
                <p>
                  保护用户隐私是“自然资源统一确权登记”的一项基本政策，“自然资源统一
                  确权登记”保证不对外公开或向第三方提供用户注册资料及用户在使用网络服
                  务时存储在“自然资源统一确权登记”的非公开内容，但下列情况除外：
                </p>
                <p>（1）事先获得用户的明确授权；</p>
                <p>（2）根据有关的法律法规要求；</p>
                <p>（3）按照相关政府主管部门的要求；</p>
                <p>（4）为维护社会公众的利益；</p>
                <p>（5）为维护“自然资源统一确权登记”的合法权益。</p>
                <h4>5. 免责声明</h4>
                <p>
                  用户明确同意其使用“自然资源统一确权登记”网络服务所存在的风险将完全
                  由其自己承担；因其使用“自然资源统一确权登记”网络服务而产生的一切后
                  果也由其自己承担，“自然资源统一确权登记”对用户不承担任何责任。
                </p>
                <p>
                  “自然资源统一确权登记”不担保网络服务一定能满足用户的要求，也不担保网
                  络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
                </p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="refuse">我拒绝</el-button>
                <el-button type="primary" @click="confirmAgree">我同意遵守协议</el-button>
            </span>
        </el-dialog>

        <el-dialog
                custom-class="signup-dialog"
                :visible.sync="signupDialog"
                :show-close="false"
                width="360px"
                top="180px">
            <div class="signup-succ">
                <p class="avatar">
                    <img src="../../assets/images/signup-succ.png"/>
                </p>
                <p>{{signupForm.username}}，恭喜您成功注册！</p>
<!--                <p>{{toSignupSec}}S后自动进入登录页面</p>-->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="login">立即登录</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>

    import {addUser, isUserNameExist} from "../../api/systemService";
    import {GenerateUuid} from "../../utils/uuid-utils";
    import loginService from "../../api/loginService";

    export default {
        components: {},
        data() {

            var validateUsername = (rule, value, callback) => {
                if (value == '') {
                    callback(new Error('请输入用户名'))
                } else {
                    var uPattern = /^[a-zA-Z0-9_-]{4,16}$/;
                    if (!uPattern.test(value)) {
                        callback(new Error('用户名为4到16位字母、数字、下划线'))
                    } else {
                        //验证用户名是否唯一
                        isUserNameExist(value).then(res => {
                            if (res.data) {
                                callback(new Error('用户名已被使用'))
                            } else {
                                callback();
                            }
                        }, error => {
                            callback("用户名校验失败，请重试");
                        });
                    }
                }
            }
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
                    if (pPattern.test(value)) {
                        callback();
                    } else {
                        callback(new Error('最少6位(包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符)'))
                    }

                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.signupForm.passWord) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            var validatePhone = (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('请输入手机号'));
                }else{
                    var patten = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
                    if (patten.test(value)) {
                        callback();
                    } else {
                        callback(new Error('请输入有效手机号码'))
                    }
                }
                callback();
            };
            var validataIdcard = (rule, value, callback) => {
                if (value != '' && this.signupForm.certificatetype == '') {
                    callback(new Error('请选择证件类型'))
                } else if (value != '' && this.signupForm.certificatetype == '1') {
                    var patten = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
                    if (patten.test(value)) {
                        callback();
                    } else {
                        callback(new Error('身份证件格式有误'))
                    }
                }
                callback();
            };
            return {
                signupForm: {
                    username: '',
                    passWord: '',
                    confirmPwd: '',
                    certificatetype: '',
                    certificatecode: '',
                    staffName: '',
                    email: '',
                    mobile: '',
                    varCode: '',
                    s: 60,
                    checked: false,
                    isSend: false,
                    userCode:'',
                },
                dialogVisible: false,
                signupDialog: false,
                toSignupSec: 3,
                signupTimer: null,

                rules: {
                    mobile: [
                      {validator: validatePhone, required: true, trigger: 'blur'}
                    ],
                    username: [
                       { validator: validatePhone,required: true, trigger: 'blur'}
                    ],
                    varCode: [
                      { required: true,message: '请输入验证码', trigger: 'blur'}
                    ],
                    staffName: [{
                        required: true, message: '请输入昵称', trigger: 'blur'
                    }],
                    passWord: [
                        {validator: validatePass, required: true, trigger: 'blur'}
                    ],
                    confirmPwd: [
                        {validator: validatePass2, required: true, trigger: 'blur'}
                    ],
                    telephone: [
                        {validator: validatePhone, trigger: 'blur'}
                    ],
                    certificatecode: [
                        {validator: validataIdcard, trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            /**
             * 加载验证码
             */
            getCode() {
              if (this.isPhoneNum(this.signupForm.username)) {
                  this.uuid = GenerateUuid();
                  sessionStorage.setItem("registerKey", this.uuid)
                  //获取验证码接口
                  const loading = this.$loading({
                    lock: true,
                    text: '获取中...',
                    background: 'rgba(255, 255, 255, 0.7)'
                  });
                  loginService.getIdentifyingCode(this.uuid + "-" + this.signupForm.username,this.signupForm.username).then(res => {
                    loading.close();
                    if(res.data.includes('成功')){
                      this.$message.success('发送成功');
                      this.setTime()
                    }else {
                      this.$message.error(res.data);
                    }
                  }).catch(e => {
                    loading.close();
                    this.$message.error(e);
                  })
              }
            },
            setTime() {
              if (this.signupForm.s === 0) {
                this.signupForm.isSend = false
                return;
              } else {
                this.signupForm.isSend = true
                this.signupForm.s--;
              }
              let _this = this;
              setTimeout(function () {
                _this.setTime()
              }, 1000)//每1000毫秒执行一次
            },
            //校验手机号是否合法
            isPhoneNum(phoneNum) {
              if(phoneNum === ''){
                 this.$message.warning("请输入手机号！");
                 return false
              }
              var myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
              if (!myreg.test(phoneNum)) {
                this.$message.warning('请输入有效的手机号码！');
                return false;
              }
              return true
            },
            /**
             * 注册
             */
            signup() {
                this.$refs.signupForm.validate(
                    (valid) => {
                        if (valid) {
                         let param = {
                             username: this.signupForm.username,
                             passWord:this.signupForm.passWord
                          };
                          const registerKey = sessionStorage.getItem('registerKey');
                          const registerCode = this.signupForm.varCode
                          addUser(registerKey + "-" + this.signupForm.username,registerCode,param).then(res => {
                                var that = this
                                this.signupDialog = true
                                // this.signupTimer = setInterval(function () {
                                //     that.toSignupSec -= 1
                                //     if (that.toSignupSec == 0) {
                                //         clearInterval(that.signupTimer)
                                //         that.$router.push('/login')
                                //         that.toSignupSec = 3
                                //     }
                                // }, 1000)
                            });
                        }
                    });
            },
            login() {
                this.$router.push('/login')
                /*if (this.toSignupSec > 0) {
                    clearInterval(this.signupTimer)
                    this.$router.push('/login')
                    this.toSignupSec = 3
                }*/
            },
            refuse(){
              this.dialogVisible = false;
              this.signupForm.checked = false;
            },
            confirmAgree(){
              this.dialogVisible = false;
              this.signupForm.checked = true;
          }
        }
        ,
        mounted() {
            if (this.$route.name == 'signup') {
                document.getElementsByClassName('menu')[0].style.display = 'none';
            }
        }
        ,
        created() {

        }
    }
</script>
<style lang="scss">
    .signup-box {
        background-color: #F5F5F5;
        padding-top: 20px;

        .center {
            background-color: #fff;

            .panel {
                width: 480px;
                margin: 0 auto;
                padding: 30px 0;

                .title {
                    text-align: center;
                    color: #000;
                    font-size: 28px;
                    margin-bottom: 30px;
                }

                .el-form {
                    .el-form-item {
                        .el-form-item__label {
                            font-size: 16px;
                            color: #333;
                        }

                        .el-form-item__content {
                            font-size: 16px;

                            .el-select {
                                width: 380px;
                            }

                            .el-checkbox__label {
                                font-size: 16px;
                            }

                            .is-account {
                                a {
                                    color: #1AA4E7;
                                }
                            }

                            .el-input-group__append {
                                .el-button {
                                    width: 120px;
                                    font-size: 16px;
                                    color: #fff;
                                    text-align: center;
                                    padding: 9px 20px 10px;;
                                    background-color: #039be5;
                                    border-top-left-radius: 0;
                                    border-bottom-left-radius: 0;
                                }

                                .sent {
                                    color: #999;
                                    cursor: no-drop;
                                    background-color: #E5E5E5;
                                }
                            }
                        }
                    }

                    .signup-tips {
                        .agreement {
                            color: #1AA4E7;
                            cursor: pointer;
                        }
                    }

                    .signup-btn {
                        .el-button {
                            color: #fff;
                            width: 100%;
                            background: rgba(3, 155, 229, 1);
                            border-radius: 4px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .el-dialog__wrapper {
            .el-dialog__body {
                color: #333;
                line-height: 28px;
                max-height: 500px;
                overflow-y: auto;

            }

            .el-dialog__footer {
                text-align: center;
            }

            .signup-dialog {
                .el-dialog__body {
                    padding: 10px 20px;

                    .signup-succ {
                        text-align: center;
                        font-size: 16px;
                        color: #333;

                        .avatar {
                            margin-bottom: 20px;

                            img {
                                width: 82px;
                                height: 80px;
                            }
                        }
                    }
                }

                .el-dialog__footer {
                    padding-bottom: 40px;

                    .el-button {
                        padding: 9px 20px;
                        width: 190px;
                    }
                }
            }
        }
    }
</style>
